import { Link } from "gatsby"
import React, { useContext } from "react"
import { expertiseAndServicesPsgeRoutesContext } from "../../../data/context"
import { waysToWork } from "../../../data/waysToWork"
import Button from "../../Button"
import SectionHeader from "../../SectionHeader"
import ExpertiseCard from "../Expertise"
import { data } from "../expNServData"
import WayToWork from "../WayToWork"
import * as styles from "./ExpertiseNServicesPage.module.scss"

export const ExpertiseNServicesPage: React.FC = () => {
  const { technologies, services } = useContext(expertiseAndServicesPsgeRoutesContext)

  return (
    <div className={styles.main} id="expertise">
      <section>
        <div className={styles.container}>
          <SectionHeader
            main={"Our Expertise and Services"}
            secondary={"What We Do"}
            classNameH2={styles.h2}
          />
          <div className={styles.info}>
            <div className={styles.cards}>
              {data.map(item => (
                <ExpertiseCard item={item} key={item.text} />
              ))}
            </div>
            <div className={styles.right}>
              <div className={styles.rightHeading}>Three ways to work</div>
              <ol className={styles.waysToWork}>
                {waysToWork.map(wayToWork => {
                  return <li className={styles.waysToWorkItem}>
                    <WayToWork
                      key={wayToWork.title}
                      title={wayToWork.title}
                      details={wayToWork.details}
                    />
                  </li>
                })}
              </ol>
            </div>
            <div className={styles.bottomText}>
              More about
              <Link
                to={technologies.to}
              >
                <span className={styles.bottomLink}>
                  {`${technologies.text}`}
                </span>
              </Link>
              and
              <Link
                to={services.to}
              >
                <span className={styles.bottomLink}>
                  {`${services.text}`}
                </span>
              </Link>
            </div>
            <div className={styles.bottomRight}>
              <Button
                className={styles.button}
                text={"Leadership"}
                color={"violet"}
                onClick={() => window.location.href = "/leadership"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

